import React, { useState } from 'react';
import styled from 'styled-components';
import teach from '../images/teach.svg';
import { BannerButton, BannerButtonSmall } from './banner-button';
import content1 from '../images/content-1.png';
import content2 from '../images/content-2.png';
import { Column } from './defaults';
import { useSelector } from 'react-redux';
import api from '../utils/api';
import { LoadingMask } from './loading-mask';
import { downloadPDF } from '../utils/downloader';
import { NotificationManager } from 'react-notifications';

const Text = styled.div`
    color: #051F73;
    font-size: 24px;

    @media (max-width: 762px) {
        font-size: 24px;
    }
`;

const Background = styled.img`
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    height: 400%;
    width: 100%;
    margin-top: -600px;
    object-fit: cover;
    
    @media (max-width: 762px) {
        margin-top: initial;
        height: 100%;
        width: 100%;
    }
`;

const Banner = styled(Column)`
    position: relative;
    border-radius: 32px;
    overflow: hidden;
    padding: 40px;

    @media (max-width: 762px) {
        border-radius: 20px;
        padding: 30px;
    }  
`;

const Contents = styled(Column)`
    z-index: 2;
    flex: 1;
`;

const TextContent1 = styled(Text)`
    width: 70%;
    font-size: 20px;

    @media (max-width: 762px) {
        width: 100%;
    }
`;



export const Content1 = ({}) => {
    
    const [loading, setLoading] = useState(false);
    let kits = [...(useSelector((state) => state.user.userData?.kits) || [])];
    kits.sort((a, b) => new Date(a.registered_at) - new Date(b.registered_at))
    kits = kits.filter((k) => k.test_types[0]?.name === 'Age');
    const processedKits = kits.filter((k) => k.test_types[0]?.name === 'Age' && k.status === 'PROCESSED' );
    const lastAgeKit = kits[kits?.length - 1];
    const lastProcessedKit = processedKits.length > 0 && processedKits[processedKits.length - 1];

    const onClick = async () => {
        if (!lastAgeKit) window.open('https://trumelabs.com/biological-age-test/', '_blank')
        if (!lastProcessedKit) return;
        setLoading(true)
        const res = await api.get(`/reports?barcode=${lastProcessedKit?.barcode}`);
        const url = res.data.url;
        if (!url) NotificationManager.success('Your report is being processed. Please check back later.')
        else await downloadPDF(url);
        setLoading(false);
    }

    const outerText = !!lastProcessedKit  ?
    'Discover what shaped your aging journey' : lastAgeKit?.status === 'IN_LAB' || lastAgeKit?.status === 'FLAGGED' ?
    "We're processing your results." : lastAgeKit?.status === 'REGISTERED' ?
    'Please send your Age Kit back.' : !lastAgeKit ?
    'Start your rejuvenation journey today!' : 
    null

    const innerText = !!lastProcessedKit ? 
        'Download Report' : !lastAgeKit ?
        'Order Test' :
        null

    return <Banner style={{ gridArea: 'content1' }}>
        {loading && <LoadingMask />}
        
        <Contents>
            <TextContent1 style={{ width: '70%'}}>Numerous elements shape your aging process, and our goal is to guide you in understanding them.</TextContent1>
            <div style={{ flex: 1 }} />
            
            {outerText && <BannerButton
            onClick={onClick} 
            innerTextMobile={innerText} 
            innerText={innerText} 
            icon={teach} 
            outerText={outerText} />}
        </Contents>
        <Background src={content1}/>
    </Banner>
}


const TextContent2 = styled(Text)`
    z-index: 3;
    width: 50%;

    @media (max-width: 762px) {
        width: initial;
    }
`;

const Background2 = styled.img`
    height: 150%;
    left: initial;
    object-fit: contain;
    top: -100px;
    z-index: 0;
    right: -200px;
    position: absolute;

    @media (max-width: 762px) {
        position: relative;
        height: 300px;
        width: 300px;
        top: initial;
        right: initial;
        align-self: center;
    }
`;

export const Content2 = () => {


    return <Banner style={{ gridArea: 'content2', background: 'linear-gradient(102.88deg, #F9F6F0 0%, #F9E5B9 100%)' }}>
        <Contents>
            <TextContent2>For best age results, we recommend completing 2 or more tests 4-6 weeks apart.</TextContent2>
            <Background2  src={content2}/>
            <div style={{ flex: 1 }} />
            <BannerButtonSmall 
            arrowWrapperStyle={{ marginLeft: 0 }}
            textStyle={{ color: 'rgb(5, 31, 115)' }}
            style={{ paddingLeft: '30px', background: 'white', alignSelf: 'flex-end'}}>
                Order New Test
            </BannerButtonSmall>
        </Contents>
    </Banner>
}