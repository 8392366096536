import React from 'react';
import styled from 'styled-components';
import { Column, FlexRow, Row } from './defaults';
import arrowUp from '../images/graph-arrow-white.svg'
import arrow from '../images/arrow-navy.svg';


const BannerButtonWrapper = styled(FlexRow)`
    background: rgba(0,0,0, 0.1);
    padding-right: 16px;
    padding-left: 40px;
    height: 88px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    align-items: center;
    align-self: stretch;
    backdrop-filter: blur(20px);

    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
    opacity: ${props => props.disabled ? 0.5 : 'initial'};


    @media (max-width: 762px) {
        height: initial;
        padding: 20px;
        border-radius: 20px;
        align-items: stretch;
    }
`;

const BannerButtonOuterIcon = styled.img`
    height: 20px;
    margin-right: 8px;
`;

const BannerButtonOuterText = styled.div`
    color: ${ props => props.blackText ? '#051F73' : 'white'};
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    flex: 1;
    margin-right: 10px;

    @media (max-width: 762px) {
        font-size: 16px;
    }
`;

const BannerButtonInnerButton = styled(Row)`
    border-radius: 100px;
    background: white;
    height: 56px;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    justify-content: center
`;

const BannerButtonInnerText = styled.div`
    white-space: nowrap;
    color: rgba(5, 31, 115, 1);
    font-size: 20px;
    margin-right: 20px;
    line-height: 20px;
    @media (max-width: 762px) {
        font-size: 16px;
        display: ${props => !props.mobile ? 'none' : 'initial'}
    }
    @media (min-width: 762px) {
        display: ${props => props.mobile ? 'none' : 'initial'}
    }
`

const Spacer = styled.div`
    @media (min-width: 762px) {
        flex: 1;
    } 

    @media (max-width: 762px) {
        height: 20px;
    }
`;

const BannerButtonInnerIcon = styled.img`
`;

export const BannerButton = ({ blackText, disabled, innerTextMobile, icon = arrowUp, outerText, innerText, onClick, style = {}}) => {
    

    return <BannerButtonWrapper disabled={disabled} style={style}>
        <Row style={{ alignItems: 'center'}}>
            <BannerButtonOuterIcon src={icon} />
            <BannerButtonOuterText blackText={blackText} >{outerText}</BannerButtonOuterText>
        </Row>
       <Spacer />
        {innerText !== null && <BannerButtonInnerButton onClick={onClick}>
            {innerText !== null && <BannerButtonInnerText>{innerText}</BannerButtonInnerText>}
            {innerTextMobile && <BannerButtonInnerText mobile>{innerTextMobile}</BannerButtonInnerText>}
            <BannerButtonInnerIcon src={arrow} />
        </BannerButtonInnerButton>}
    </BannerButtonWrapper>

}


const ButtonWrapper = styled(Row)`
    background: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 106px;
    height: 60px;
    align-items: center;
    padding-right: 10px;
    padding-left: 20px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;
`;


const Icon = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 10px;
`;

const Dot = styled(Column)`
    height: 45px;
    width: 45px;
    border-radius: 100px;
    background: white;
    margin-left: 20px;
    align-items: center;
    justify-content: center;

    img {
        height: 15px;
        width: 15px;
    }
`;

export const BannerButtonSmall  = ({
    children, 
    arrowWrapperStyle = {}, 
    textStyle = {}, 
    icon, 
    ...props
}) => {

    return <ButtonWrapper {...props}>
        {icon && <Icon src={icon} />}
        <div style={textStyle}>{children}</div>
        <Dot style={arrowWrapperStyle}>
            <img src={arrow} alt='open'/>
        </Dot>
    </ButtonWrapper>
}
